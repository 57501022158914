import React, { useEffect, useState } from "react";
import { axios } from "../../lib/constants/api";
import { useParams } from "react-router-dom";
import "./shipping.style.scss";

export const ShippingComponent = () => {
  const { shippingId } = useParams();
  const [shippingData, setShippingData] = useState(null);

  useEffect(() => {
    const fetchShippingData = async () => {
      try {
        const response = await axios.get(`/shippings/${shippingId}`);
        setShippingData(response.data);
      } catch (error) {
        console.error("Error fetching shipping data:", error);
      }
    };

    fetchShippingData();
  }, []);

  useEffect(() => {
    // Add the class when the component mounts
    document.body.classList.add("shipping");
  }, []);

  if (!shippingData) return <div>Loading...</div>;

  const {
    shippingNumber,
    status,
    originCountry,
    originCity,
    destinationCountry,
    destinationCity,
    exitedAt,
    items,
    details,
  } = shippingData;

  return (
    <div className="shipping-container">
      <div className="shipping-brand">
        <img
          src="https://imagedelivery.net/V5ATA0DR_GnXU4EaWbiBDw/027c0d0e-0528-43d3-4d9f-5d7b7526af00/w=200,h=52"
          alt=""
        />
        <div className="shipping-title">فاکتور خرید</div>
      </div>
      <div className="shipping-header">
        <div className="shipping-info">
          <div className="shipping-number">
            <span>شناسه: {shippingNumber}</span>
            <span
              className={`status ${
                status === "ثبت شده" ? "status-registered" : ""
              }`}
            >
              {status}
            </span>
          </div>
          <div>کشور مبدا: {originCountry}</div>
          <div>شهر مبدا: {originCity}</div>
        </div>
        <div className="shipping-info">
          <div>کشور مقصد: {destinationCountry}</div>
          <div>شهر مقصد: {destinationCity}</div>
          <div>تاریخ خروج: {exitedAt}</div>
        </div>
        <div className="shipping-info">
          <div>شماره کامیون: {"-"}</div>
          <div>حمل کننده: {"-"}</div>
          <div>کد گروه گمرک: {"-"}</div>
        </div>
      </div>
      <div className="shipping-body">
        <table className="shipping-table">
          <thead>
            <tr>
              <th>نام کولی</th>
              <th>شناسه کولی</th>
              <th>وزن</th>
              <th>شناسه آرمان</th>
              <th>محصول</th>
              <th>هزینه</th>
            </tr>
          </thead>
          <tbody>
            {(items || []).map((item, index) => (
              <tr key={index}>
                <td>{item.coliName}</td>
                <td>{item.coliId}</td>
                <td>{item.coliWeight}</td>
                <td>{item.sku}</td>
                <td>{item.productName}</td>
                <td>{item.cost}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <table className="shipping-table shipping-details">
          <tbody>
            <tr>
              <td>وزن کل بارنامه </td>
              <td>{details.totalWeight} کیلوگرم</td>
            </tr>
            <tr>
              <td>واحد پول </td>
              <td>{details.currency}</td>
            </tr>
            <tr>
              <td>هزینه کل</td>
              <td>{details.totalCosts}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
