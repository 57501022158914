import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { axios } from "../../lib/constants/api";
import "./poster.style.scss";

const metaLabelMap = {
  machine_type: "تیپ",
  post_title: "نام دستگاه",
  company: "شرکت سازنده",
  model: "مدل",
  year_of_manufacturing: "سال ساخت",
  made_in: "کشور سازنده",
  controller: "کنترلر",
  controller_model: "مدل کنترلر",
  chip_conveyor: "سفاله کش",
  turret_type: "نوع تارت",
  number_of_turret_tools: "تعداد ابزار تارت",
  turret_engine_type: "نوع موتور تارت",
  tailstock: "مرغک",
  lunette: "لینت",
  chuck_size: "قطر سه نظام (میلی متر)",
  machining_diameter: "قطر ماشین کاری (میلی متر)",
  turning_diameter: "قطر قابل گردش (میلی متر)",
  machining_length: "طول قابل تراشکاری (میلی متر)",
  have_c_axis: "محور c",
  have_y_axis: "محور y",
  spindle_bore_type: "گلو اسپیندل",
  spindle_bore_diameter: "قطر گلویی (میلی متر)",
  spindle_type: "نوع اسپیندل",
  max_spindle_speed: "حداکثر دور اسپیندل (RPM)",
  table_dimensions: "ابعاد میز (میلی متر)",
  number_of_axes: "تعداد محور",
  tool_changer_type: "نوع تول چنجر",
  number_of_tools: "تعداد ابزار",
  tool_std: "استاندارد ابزار",
  tool_size: "اندازه ابزار",
  axes_mechanism: "مکانیزم محوره",
  "x-axis-travel": "کورس محور X (میلی متر)",
  "y-axis-travel": "کورس محور Y (میلی متر)",
  "z-axis-travel": "کورس محور Z (میلی متر)",
  "w-axis-travel": "کورس محور W (میلی متر)",
  supplementary_details: "توضیحات تکمیلی",
};

export const PosterComponent = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState();
  const [search] = useSearchParams();
  const onlyFirstPage = search.get("page") === "first";
  const onlySecondPage = search.get("page") === "second";

  useEffect(() => {
    const handleFetchProduct = async () => {
      const fetchedProduct = await axios.get(`/products/panel/${productId}`);
      if (fetchedProduct.data) {
        setProduct(fetchedProduct.data);
      }
    };
    handleFetchProduct();
  }, [productId]);

  const getProductMeta = () => {
    if (!product) return [];

    const commonMeta = [
      "_yoast_wpseo_title",
      "year_of_manufacturing",
      "made_in",
      "controller",
      "machine_type",
      "chip_conveyor",
      "turret_type",
      "number_of_turret_tools",
      "turret_engine_type",
      "tailstock",
      "lunette",
    ];
    const isMachineEquals = (machineName) =>
      product.categories.some(({ name }) => name.includes(machineName));

    const specialMeta = isMachineEquals("تراش")
      ? ["chuck_size", "machining_diameter", "max_spindle_speed"]
      : ["x-axis-travel", "y-axis-travel", "z-axis-travel", "table_dimensions"];

    const posterAttrs = product.meta_data.filter(({ key }) =>
      [...specialMeta, ...commonMeta].includes(key)
    );

    return posterAttrs;
  };
  const posterImage =
    (product && product.images && product.images[0].src) || undefined;

  const productData = getProductMeta();
  const productDataToShow = productData.filter(
    ({ key }) => !["_yoast_wpseo_title"].includes(key)
  );

  const getArmanAttr = () => {
    const armanSku = `شناسه آرمان: ${product.sku || "-"}`;
    return armanSku;
  };

  // const productCategory = {
  //   if (isMachineEquals("machine")) {
  // }

  const seoTitle = productData.find(({ key }) => key === "_yoast_wpseo_title");
  const summarizedProductName = seoTitle
    ? seoTitle.value
    : product
    ? product.name
    : "-";

  return (
    product && (
      <>
        {onlyFirstPage && (
          <div className="poster-n-container">
            <img
              loading="lazy"
              className="poster-image"
              src={posterImage}
              alt=""
            />
            <div className="sku-container">{getArmanAttr()}</div>
            <div className="title-container">
              <h1>{summarizedProductName}</h1>
            </div>
          </div>
        )}
        {onlySecondPage && (
          <div className="poster-n-container details">
            <div className="details-table">
              <div className="header">
                <h1>{summarizedProductName}</h1>
              </div>
              {productDataToShow
                .filter(({ value }) => !!value)
                .slice(0, 9)
                .map(({ value, key }, index) => (
                  <div className="row">
                    <div className="content">
                      <div className="row-number">{index + 1}</div>
                      {metaLabelMap[key]
                        ? metaLabelMap[key].replace(/\(میلی متر\)/g, "")
                        : key}
                    </div>
                    <div className="content">{value}</div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </>
    )
  );
};
