import React, { useEffect, useState } from "react";
import { axios } from "../../lib/constants/api";
import { useParams } from "react-router-dom";
import "./gallery.style.scss";
import { Loader } from "../../lib/ui/components";
import Compressor from "compressorjs";

// eslint-disable-next-line no-unused-vars
const compressAndConvertToWebP = (file, quality = 0.7) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: quality, // Compression quality
      mimeType: "image/webp", // Convert image to WebP format
      success(result) {
        // Convert the result (which is a File) to a Blob
        result.arrayBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: "image/webp" });
          resolve(blob); // Return the Blob
        });
      },
      error(err) {
        reject(err); // If there's an error during compression
      },
    });
  });
};

export const GalleryComponent = () => {
  const { productId } = useParams();
  const [productData, setProductData] = useState(null);
  const [productImages, setProductImages] = useState([]);
  const [isLoadingVisible, setIsLoadingVisible] = useState(true);
  const [uploadingImages, setUploadingImages] = useState([]);
  const [uploadingImagesPreview, setUploadingImagesPreview] = useState([]);
  const [uploadingIndex, setUploadingIndex] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [deletingIndexes, setDeletingIndexes] = useState([]);
  const [mainImagePreview, setMainImagePreview] = useState();

  const isQueueEmpty = uploadingImages.length === 0;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          `/upload-media/product-images/${productId}`
        );
        setProductImages(response.data.images);
        setIsLoadingVisible(false);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };

    fetchImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/products/panel/${productId}`);
        setProductData(response.data);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Add the class when the component mounts
    document.body.classList.add("gallery");
  }, []);

  const handleUploadImages = async () => {
    setIsUploading(true);
    let currentUploadingIndex = -1;
    const uploadedUrls = [];

    for (const uploadingImage of uploadingImages) {
      currentUploadingIndex++;
      setUploadingIndex(currentUploadingIndex);

      // const file = await compressAndConvertToWebP(uploadingImage, 0.6); // Pass the desired quality (0.6 = 60%)

      const formData = new FormData();
      // formData.append("file", file, uploadingImage.name);
      formData.append("file", uploadingImage);

      try {
        const response = await axios.post(`/upload-media/media`, formData);
        const responseData = await response.data;
        uploadedUrls.push(responseData.source_url);
      } catch (error) {
        setIsUploading(false);
        console.error("Error uploading image:", error);
      }
    }

    try {
      const payload = uploadedUrls.map((url) => ({ src: url }));

      await axios.put(`/upload-media/product-images/${productId}`, payload);

      const fetchResponse = await axios.get(
        `/upload-media/product-images/${productId}`
      );
      setProductImages(fetchResponse.data.images);
      setUploadingImages([]);
      setUploadingImagesPreview([]);
    } catch (error) {
      setIsUploading(false);
      console.error("Error updating product:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const mainImage = productImages[0];
  const otherImages = productImages.slice(1, productImages.length);

  const handleRemovePendingImage = (index) => {
    const newUploadingImages = uploadingImages.filter((_, i) => i !== index);
    const newUploadingImagesPreview = uploadingImagesPreview.filter(
      (_, i) => i !== index
    );
    setUploadingImages(newUploadingImages);
    setUploadingImagesPreview(newUploadingImagesPreview);
  };

  const handleRemoveUploadedImage = async (image, index) => {
    const newProductImages = productImages.filter((_, i) => i !== index + 1);

    const clonedDeletingIndexes = [...deletingIndexes];
    setDeletingIndexes([...clonedDeletingIndexes, index]);

    try {
      const payload = newProductImages.map(({ src }) => ({ src }));

      await axios.put(
        `/upload-media/product-images/${productId}?override=true`,
        payload
      );
      setProductImages(newProductImages);

      const newDeletingIndexes = clonedDeletingIndexes.filter(
        (_, i) => i !== index
      );
      setDeletingIndexes(newDeletingIndexes);
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleOtherImagesChange = async (event) => {
    const files = event.target.files;

    const newUploadingImages = [...uploadingImages];
    const newUploadingImagesPreview = [...uploadingImagesPreview];
    for (const file of files) {
      if (file) {
        newUploadingImages.push(file);
        newUploadingImagesPreview.push(URL.createObjectURL(file));
      }
    }
    setUploadingImages(newUploadingImages);
    setUploadingImagesPreview(newUploadingImagesPreview);
  };

  const handleMainImageUploadChange = async (event) => {
    const [file] = event.target.files;

    if (file) {
      setIsUploading(true);
      setMainImagePreview(URL.createObjectURL(file));

      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(`/upload-media/media`, formData);
        const responseData = await response.data;

        const payload = [{ src: responseData.source_url }];

        await axios.put(
          `/upload-media/product-images/${productId}?mainImage=true`,
          payload
        );
        const fetchResponse = await axios.get(
          `/upload-media/product-images/${productId}`
        );
        setProductImages(fetchResponse.data.images);
        setMainImagePreview(null);
      } catch (error) {
        setIsUploading(false);
        console.error("Error updating product:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const shouldShowDeleteButton = (index) =>
    deletingIndexes.length ? deletingIndexes.includes(index) : !isUploading;

  const openProductPage = () => {
    if (!productData) return;
    window.open(productData.permalink, "_self");
  };

  return (
    <>
      <Loader visible={isLoadingVisible} text="در حال بارگیری تصاویر..." />

      <div className="gallery-container">
        <button
          className="back-btn btn"
          disabled={!productData || !productData.permalink}
          onClick={() => openProductPage()}
        >
          بازگشت به صفحه محصول
        </button>
        {productData && (
          <h3 className="main-title">{`تصاویر محصول ${productData.name}`}</h3>
        )}
        <div className="main-image-container">
          <div className="flex">
            <h3 className="container-title">تصویر اصلی دستگاه</h3>
            <div className="upload">
              <input
                disabled={isUploading}
                onChange={handleMainImageUploadChange}
                type="file"
                id="main-image-upload"
              />
              <label htmlFor="main-image-upload">
                <span className={`btn ${isUploading && "disabled"}`}>
                  {isUploading ? "در حال بارگزاری..." : "بارگزاری تصویر"}
                </span>
              </label>
            </div>
          </div>
          {mainImage && (
            <img
              className="image-item main"
              src={mainImagePreview || mainImage.src}
              alt=""
            />
          )}
        </div>
        <hr />
        <div className="images-container">
          <div className="flex">
            <h3 className="container-title">تصاویر دستگاه</h3>
            <div className="upload">
              <input
                disabled={isUploading}
                onChange={handleOtherImagesChange}
                type="file"
                multiple
                id="other-images-upload"
              />
              <label htmlFor="other-images-upload">
                <span className={`btn ${isUploading && "disabled"}`}>{`${
                  otherImages.length > 0 ? "افزودن" : "بارگزاری"
                } تصویر`}</span>
              </label>
            </div>
          </div>
          {otherImages.map((image, index) => (
            <div className="image-container">
              <div className="cover-action">
                {shouldShowDeleteButton(index) && (
                  <button
                    className={deletingIndexes.includes(index) ? "texted" : ""}
                    disabled={deletingIndexes.includes(index)}
                    onClick={() => handleRemoveUploadedImage(image, index)}
                  >
                    {deletingIndexes.includes(index) ? "در حال حذف" : `\u00D7`}
                  </button>
                )}
              </div>
              <img className="image-item" src={image.src} alt={image.src} />
            </div>
          ))}
          {uploadingImagesPreview.map((image, index) => (
            <div className="image-container preview">
              <div className="cover">
                {uploadingIndex > index
                  ? "آپلود شده"
                  : uploadingIndex === index
                  ? "در حال آپلود"
                  : "در صف آپلود"}
              </div>
              <div className="cover-action">
                {!isUploading && (
                  <button onClick={() => handleRemovePendingImage(index)}>
                    &times;
                  </button>
                )}
              </div>
              <img className="image-item" src={image} alt="text" />
            </div>
          ))}
          {!isQueueEmpty && (
            <button
              disabled={isUploading}
              onClick={() => handleUploadImages()}
              className="confirm-btn"
            >
              {isUploading ? "در حال بارگزاری..." : "بارگزاری تصاویر"}
            </button>
          )}
        </div>
      </div>
    </>
  );
};
