import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { InventoryPrintComponent } from "../pages";
import "../assets/styles/global.style.scss";
import { PosterOldComponent } from "../pages/poster/poster.component";
import { PosterComponent } from "../pages/poster-new/poster.component";
import { CataloguePrintComponent } from "../pages/catalogue-print";
import { ProductCataloguePrintComponent } from "../pages/product-catalogue-print";
import { LabelComponent } from "../pages/label/label.component";
import { InvoiceComponent } from "../pages/invoice";
import { ShippingComponent } from "../pages/shipping";
import { GalleryComponent } from "../pages/gallery";

export const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/inventory/:categoryId"
          element={<InventoryPrintComponent />}
        />
        <Route
          path="/catalogue/:matchingId"
          element={<CataloguePrintComponent />}
        />
        <Route
          path="/product-catalogue/:productId"
          element={<ProductCataloguePrintComponent />}
        />
        <Route path="/poster-old/:productId" element={<PosterOldComponent />} />
        <Route path="/poster/:productId" element={<PosterComponent />} />
        <Route path="/label" element={<LabelComponent />} />
        <Route path="/invoices/:invoiceId" element={<InvoiceComponent />} />
        <Route path="/shippings/:shippingId" element={<ShippingComponent />} />
        <Route path="/gallery/:productId" element={<GalleryComponent />} />
      </Routes>
    </Router>
  );
};
