import React, { useEffect, useState } from "react";
import { axios } from "../../lib/constants/api";
import { useParams } from "react-router-dom";
import "./invoice.style.scss";

export const InvoiceComponent = () => {
  const { invoiceId } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);

  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const response = await axios.get(`/invoices/${invoiceId}`);
        setInvoiceData(response.data);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };

    fetchInvoiceData();
  }, []);

  useEffect(() => {
    // Add the class when the component mounts
    document.body.classList.add("invoice");
  }, []);

  if (!invoiceData) return <div>Loading...</div>;

  const {
    invoiceNumber,
    status,
    seller,
    country,
    reseller,
    resellerCountry,
    createdAt,
    purchasedAt,
    products,
    details,
  } = invoiceData;

  return (
    <div className="invoice-container">
      <div className="invoice-header">
        <div className="invoice-info">
          <div className="invoice-number">
            <span>شناسه: {invoiceNumber}</span>
            <span
              className={`status ${
                status === "ثبت شده" ? "status-registered" : ""
              }`}
            >
              {status}
            </span>
          </div>
          <div className="invoice-seller">
            <div>فروشنده: {seller}</div>
            <div>کشور: {country}</div>
            <div>نماینده فروش: {reseller}</div>
            <div>کشور نماینده فروش: {resellerCountry}</div>
          </div>
        </div>
        <div className="invoice-brand">
          <img
            src="https://imagedelivery.net/V5ATA0DR_GnXU4EaWbiBDw/027c0d0e-0528-43d3-4d9f-5d7b7526af00/w=200,h=52"
            alt=""
          />
          <div className="invoice-title">فاکتور خرید</div>
        </div>
        <div className="invoice-info">
          <div>تاریخ ایجاد: {createdAt}</div>
          <div>تاریخ خرید: {purchasedAt}</div>
        </div>
      </div>
      <div className="invoice-body">
        <table className="invoice-table">
          <thead>
            <tr>
              <th>قیمت نهایی</th>
              <th>قیمت</th>
              <th>وزن</th>
              <th>شناسه آرمان</th>
              <th>محصول</th>
            </tr>
          </thead>
          <tbody>
            {(products || []).map((product, index) => (
              <tr key={index}>
                <td>{product.actualPrice}</td>
                <td>{product.price}</td>
                <td>{product.weight}</td>
                <td>{product.sku}</td>
                <td>{product.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <table className="invoice-table invoice-details">
          <tbody>
            <tr>
              <td>واحد پول</td>
              <td>{details.currency}</td>
            </tr>
            <tr>
              <td>مبلغ کل فاکتور</td>
              <td>{details.invoiceTotalPrice}</td>
            </tr>
            <tr>
              <td>پرداخت نقدی</td>
              <td>{details.cash}</td>
            </tr>
            <tr>
              <td>کمیسیون فروش</td>
              <td>{details.commission}</td>
            </tr>
            <tr>
              <td>مالیات و عوارض</td>
              <td>{details.tax}</td>
            </tr>
            <tr>
              <td>مبلغ نهایی فاکتور</td>
              <td>{details.finalPrice}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
